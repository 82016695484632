<app-breadcrumb [title]="'Blog Details'" [breadcrumb]="'Blog Details'"></app-breadcrumb>
<!--section start-->
<section class="blog-detail-page section-b-space">
    <div class="container">
        <div class="row" *ngIf="isLoading">
            <div class="col-sm-12 blog-detail">
                <app-royal-loading></app-royal-loading>
            </div>
        </div>
        <div class="row" *ngIf="!isLoading">
            <div class="col-sm-12 blog-detail">
                <img src="assets/images/blog-default.jpg" class="img-fluid" alt=""/>
                <h3>{{blogDetails.name}}</h3>
                <ul class="post-social">
                    <li>{{blogDetails.createdTime | date: 'dd MMMM yyyy'}}</li>
                    <li>Posted By : {{blogDetails.contributor}} </li>
                    <li><i class="fa fa-heart"></i> {{blogDetails.likes}} Likes </li>
                    <li><i class="fa fa-comments"></i> {{blogDetails.comments}} Comments</li>
                </ul>
                <div class="mb-5" [innerHTML]="blogDetails.description"></div>        
            </div>
        </div>
        <!-- <div class="row section-b-space" *ngIf="!isLoading">
            <div class="col-sm-12">
                <ul class="comment-section">
                    <li>
                        <div class="media">
                            <img src="assets/images/review/1.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno  <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum ex. </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media">
                            <img src="assets/images/review/2.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno  <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum ex. </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media">
                            <img src="assets/images/review/1.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno  <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum ex. </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="row blog-contact" *ngIf="!isLoading">
            <div class="col-sm-12" *ngIf="isMember">
                <h2>Leave Your Comment</h2>
                <div class="row">
                    <div class="col-md-12">
                        <label for="exampleFormControlTextarea1">Comment</label>
                        <textarea class="form-control height-150" placeholder="Write Your Comment" id="exampleFormControlTextarea1" rows="6" [(ngModel)]="comment.description"></textarea>
                        <div *ngIf="(error.description).length>0" class="error-text mt-2">
                            <div>{{error.description}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button class="btn btn-solid mt-2" (click)="commentCreate()">Post Comment</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="!isMember">
                <div>
                    <p>Please login and write the review.</p>
                    <button class="btn btn-solid" (click)="gotoLogin('comment')">Login</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->