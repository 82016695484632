import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Base64 } from 'js-base64';
import * as _ from 'lodash';
import { GlobalService } from 'src/app/_common/global';
import { LineItem } from 'src/app/_models/lineItem.model';
import { CommonPipe } from 'src/app/_pipe/common.pipe';
import { BrandsService } from 'src/app/_services/brands.service';
import { ProductService } from 'src/app/_services/product.service';
import { ShopifyService } from 'src/app/_services/shopify.service';
import { SocialService } from 'src/app/_services/social.service';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from 'src/app/shared/data/slider';

@Component({
  selector: 'app-product-details-view',
  templateUrl: './product-details-view.component.html',
  styleUrls: ['./product-details-view.component.scss'],
  providers: [CommonPipe],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailsViewComponent implements OnInit {
  public isMember = false;
  public cart_summary: any = [];
  public productId;
  public page_load = true;
  public product: any = [];
  public showRating = false;
  public schema: any = [];
  public variants: any = [];
  public product_name = "";
  public prod: any = [];
  public wappmsg = "";
  public website_url = "";
  public color_available = true;
  public action_class: any = {};
  public brand_detail: any = [];
  public product_images: any = [];
  public review: any = { "name": "", "description": "" };
  public dataLoding = false;
  public share_btn_list: any = ['facebook', 'twitter', 'linkedin', 'reddit', 'whatsapp', 'telegram', 'tumblr'];

  // default variables

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  public mobileSidebar: boolean = false;
  public activeSlide: any = 0;
  public active = 1;
  public qty_count = 1;

  constructor(
    private _router: Router,
    private _commonPipe: CommonPipe,
    private _brandsService: BrandsService,
    private route: ActivatedRoute,
    private _productService: ProductService,
    private _socialService: SocialService,
    public _global: GlobalService,
    private _shopifyService: ShopifyService
  ) {
    this.isMember = this._global.isMember();
    this.cart_summary = this._global.CARTSUMMARY;
    this.route.paramMap.subscribe((params: any) => {
      this.productId = params.get('productId');
    });

    let obj = {
      id: this.productId
    };

    this.dataLoding = true;
    this._productService.view(obj).subscribe((data) => {
      this.dataLoding = false;
      this.product = data["response"];
      this.showRating = true;
      let pVariant = false;
      let productVariant = this.product.externalId;
      if ((this.product.externalId).search('ProductVariant') != '-1') {
        pVariant = true;
        let externalId = this.product["pathToMedia"]["product_id"];
        this.product.externalId = "gid://shopify/Product/" + externalId;
      }
      let id = Base64.encode(this.product.externalId);
      this._shopifyService.getProductById(id).then((prod) => {
        this.product_name = prod.title;
        this.prod = prod;
        if (this.prod.variants && this.prod.variants[0]) {
          if (pVariant) {
            Object.entries(this.prod.variants).forEach(([key, value]) => {
              if (value["id"] == Base64.encode(productVariant)) {
                this.variants = value;
              }
            });
          } else {
            this.variants = this.prod.variants[0];
          }
        }
        this.wappmsg=this._global.urlencoder(this.product_name)+" %0A ";
        this.wappmsg+="Size/Color : "+this._commonPipe.transform(this.variants.title,['sizecolor'])+" %0A ";
        this.wappmsg+="Price : "+this.variants.price.amount+" %0A ";
        this.wappmsg+="Qty : ";
        this.website_url = this._global.SITE_URL + this._commonPipe.transform(this.product, ['produrl']);
        this._global.addMetaInfo({ website_url: this.website_url, keyword: this._global.getVal(this.product, 'tag', ''), images: this.prod.variants, title: this._global.getVal(this.product, 'name', ''), price: this.variants.price.amount, compareAtPrice: this.variants.compareAtPrice.amount,description: this._global.getVal(this.product, 'description', '') }, 'view');
        if (!this.variants.image) {
          this.color_available = false;
        }
        this.schema = {
          '@context': 'http://schema.org',
          '@type': 'ItemList',
          'itemListElement': [{
            '@type': 'ListItem',
            "position": 1,
            'url': this._global.SITE_URL + "/product/" + this.product.id,
            'name': this._global.getVal(this.product, 'name', '')
          }]
        };
      }).catch((err) => {
        console.log("shopify product error", err)
      });

      this.review.name = this._global.getVal(this.product, 'name', '');
      this.product_images = this._global.getVal(this.product, 'imagesJson', []);
      this.brands();
      let realted = [];
      Object.entries(this.product['product']).forEach(([key, value]) => {
        realted.push(key);
      });

      // Social
      let params = {
        "component": this._global.getVal(data, 'request.component', ''),
        "application": this._global.getVal(data, 'request.application', ''),
        "entityId": this._global.getVal(data, 'response.id', ''),
        "action": 'view',
        "entityType": this._global.getVal(data, 'request.application', ''),
        "actionDetails": true
      }
      this._socialService.view(params).subscribe((socialView) => {
        if (socialView["status"] == "1") {
          this.product["views"] = this._global.getVal(socialView, 'response.product.views', 0);
        }
      }, (error) => {
        console.log("error", JSON.stringify(error));
      });

      // Social services checks
      if (this.isMember) {
        let params = {
          "component": this._global.getVal(data, 'request.component', ''),
          "application": this._global.getVal(data, 'request.application', ''),
          "ids": this._global.getVal(data, 'response.id', '')
        }
        this._socialService.check(params).subscribe((sdata) => {
          if (!_.isEmpty(this._global.getVal(sdata, 'response', ''))) {
            this.action_class['like'] = this._global.getVal(sdata, 'response.' + this.productId + '.like', '');
            this.action_class['dislike'] = this._global.getVal(sdata, 'response.' + this.productId + '.dislike', '');
            this.action_class['favorite'] = this._global.getVal(sdata, 'response.' + this.productId + '.favorite', '');
            this.action_class['flag'] = this._global.getVal(sdata, 'response.' + this.productId + '.flag', '');
          }
        }, error => {
          console.log("error", JSON.stringify(error));
        });
      }
    }, error => {
      this.dataLoding = false;
      console.log("error", JSON.stringify(error));
    });
  }

  ngOnInit(): void {
  }

  brands() {
    let brand = { start: 0, rows: 10};
    this._brandsService.brand(brand).subscribe( brandData => {
      let brand = brandData['response']['docs'];
      Object.entries(brand).forEach(([key, value]) => {
        if (value["name"] == this._global.getVal(this.product, 'brand', 'no-brand')) {
          this.brand_detail = value;
        }
      });
    }, (error) => {
      console.log(" brandData error", JSON.stringify(error));
    });
  }

  getSocialServices() {
    
  }

  rating(event) {
    if (this._global.isAuthenticated()) {
      let params = {
        "component": this._global.COMPONENTS_SEC,
        "application": 'product',
        "entityId": this.product["id"],
        "action": 'rate',
        "entityType": 'product',
        "actionDetails": event.value
      };
      this._socialService.rating(params).subscribe((socialRating) => {
        if (socialRating["status"] == "1") {
          this._global.showMessage({ "type": "success", "message": "Rating Sucess", "title": "" });
          this.product["rating"] = this._global.getVal(socialRating, 'response.product.rating', 0);
        } else {
          this._global.showMessage({ "type": "warning", "message": "Please login first", "title": "" });
        }
      }, error => {
        console.log("error", JSON.stringify(error));
      });
    } else {
      this._global.showMessage({ "type": "error", "message": 'Please login first', "title": "" });
    }
  }

  // Increament
  increment() {
    this.qty_count++;
  }

  // Decrement
  decrement() {
    if (this.qty_count > 1) this.qty_count--;
  }

  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  getSize(event, variantsValue) {
    this.qty_count = this._global.getProductQty(variantsValue);
    if (variantsValue.image) {
      this.product.basePrice = variantsValue.compareAtPrice.amount;
      this.product.discountPrice = variantsValue.price.amount;
      this.wappmsg=this._global.urlencoder(this.product_name)+" %0A ";
      this.wappmsg+="Size/Color : "+this._commonPipe.transform(this.variants.title,['sizecolor'])+" %0A ";
      this.wappmsg+="Price : "+variantsValue.price.amount+" %0A ";
      this.wappmsg+="Qty : ";
    } else {
      this.color_available = false;
    }
  }

  addToWishlist(products) {

  }

  addToCart(variants) {
    // this._global.gtagClick('add_to_cart', { 'url': this._global.getCurrentUrl() + '/product/' + this.productId, id: this.productId, 'quantity': this.qty_count });
    const lineItem = new LineItem(variants, this.qty_count, this.productId, this.product_name);
    this._global.addItemToCart(lineItem);
  }

  openCheckout(checkout) {
    window.open((checkout.webUrl).replace('www.royalluxurylinen.com', this._global.CHEKOUT_URL), '_self');
  }

  buy(variants) {
    // this.btn_process=true;
    this.addToCart(variants);
    let prod_avilable = false;
    let cart = localStorage.getItem(this._global.CART_STORAGE);
    if (cart !== undefined && cart !== null) {
      let cart_list = JSON.parse(cart);
      Object.entries(cart_list).forEach(([key, value]) => {
        if (value["pid"] == this.productId) {
          prod_avilable = true;
        }
      });
    }
    // if (prod_avilable) { 
    // } else {
    //   this.addToCart(variants);
    // }

    let lineItems = JSON.parse(cart);
    this._shopifyService.createCheckout(lineItems).then(({ model, data }) => {
      if (!data.checkoutCreate.userErrors.length) {
        this.openCheckout(data.checkoutCreate.checkout);
        let i = 0;
        data.checkoutCreate.checkout.lineItems.edges.forEach(edge => {
          i++;
        });
      } else {
        data.checkoutCreate.userErrors.forEach(error => {
          alert(JSON.stringify(error));
        });
      }
    }, (err) => {
      alert(err)
    });
  }

  goToLogin() {
    this._router.navigate(['/login']);
  }
}
