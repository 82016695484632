<!--footer section -->
<footer [class]="class">
	<!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Our dream is to become a well-recognized source for prestigious products and quality brands, offering the best natural products for the bedroom and the bathroom. At Royal Luxury Linen, we specialize in the wholesale import and export of Home Textile Products.</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/royalluxlinen/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
								<li>
                                    <a href="https://www.instagram.com/royalluxlinen/?hl=en" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
								<li>
                                    <a href="https://twitter.com/linen_royal" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/linenroyalluxury/" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=+12014105803&text=I%27m%20interested&source=&data=&app_absent=" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-0">
					<div class="sub-title mb-4">
						<div class="footer-title">
                            <h4 class="mb-2">Brands</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let brand of brands">
                                    <a href="javascript:void(0)" [routerLink]="['/products/view', {adminTag :'accessories,bath,bedding',brand:brand.name}]">
                                        {{brand.name | titlecase}}
                                    </a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title mb-4">
						<div class="footer-title">
                            <h4 class="mb-2">Useful Links</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)" routerLink="/contactUs">Contact Us</a></li>
                                <li><a href="javascript:void(0)" routerLink="/help">Help</a></li>
                                <li><a href="javascript:void(0)" routerLink="/returns">Returns</a></li>
                                <li><a href="javascript:void(0)" routerLink="/terms">Terms of Use</a></li>
                                <li><a href="javascript:void(0)" routerLink="/privacy">Privacy & Security</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title mb-4">
						<div class="footer-title">
						    <h4 class="mb-2">store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>42 Colonial Drive, Piscataway, New Jersey, US - 08854</li>
						        <li class="text-inherit cursor-pointer"><i class="fa fa-phone"></i><a href="tel:+17326493609">(+1) (732) 649 3609</a></li>
						        <li class="text-inherit cursor-pointer"><i class="fa fa-envelope-o"></i><a href="mailto:linenroyalluxury@gmail.com">linenroyalluxury@gmail.com</a></li>
						        <li><i class="fa fa-clock-o"></i>Mon - Fri / 9:00AM - 05:00PM</li>
								<li><i class="fa fa-clock-o"></i>Sat - Sun / Closed</li>
								<li class="text-inherit cursor-pointer"><i class="fa fa-map-marker"></i><a href="https://maps.google.com/?daddr=42%20Colonial%20Drive%20Piscataway%20NJ%2008873,%20USA" target="_blank">Get directions</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p>Copyright <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} All Rights Reserved. Designed & Developed by <a mat-button href="http://amolconsultants.com/" target="_blank">Amol Consultants LLC</a></p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <!-- <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul> -->
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->