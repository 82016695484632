<app-breadcrumb [title]="'Contact'" [breadcrumb]="'Contact'"></app-breadcrumb>
<!--section start-->
<section class=" contact-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="title1 title5">
                    <h4>&nbsp;</h4>
                    <h2 class="title-inner1">Contact Us</h2>
                    <hr role="tournament6">
                </div>
                <p>At The Royal Luxury Linen (RLL), we are extremely careful to our customers. For every questions, concerns or even if there is anything to improve, don't hesitate to contact us ! We are always ready to answer your questions ! Please remember that our warehouse is not open on weekends and holidays but please know we will get back to you the next business day!</p>
            </div>
            <!-- <div class="col-sm-12">
                <form class="theme-form">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name">First Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Your name" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" class="form-control" id="last-name" placeholder="Email" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="review">Phone number</label>
                            <input type="text" class="form-control" id="review" placeholder="Enter your number" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Email" required="">
                        </div>
                        <div class="col-md-12">
                            <label for="review">Write Your Message</label>
                            <textarea class="form-control" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" type="submit">Send Your Message</button>
                        </div>
                    </div>
                </form>
            </div> -->
        </div>
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.4913526639934!2d-74.46796162353633!3d40.552825447344176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b89ad94bbc49%3A0x7d5acf66f19a2647!2s42%20Colonial%20Dr%2C%20Piscataway%2C%20NJ%2008854%2C%20USA!5e0!3m2!1sen!2sin!4v1701345844793!5m2!1sen!2sin" allowfullscreen></iframe>
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/phone.png" alt="Generic placeholder image">
                                <h6>Website Questions?</h6>
                            </div>
                            <div class="media-body">
                                <p>(+1) (732) 649 3609</p>
                                <p><a href="mailto:linenroyalluxury@gmail.com">linenroyalluxury&#64;gmail.com</a></p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <h6>Address</h6>
                            </div>
                            <div class="media-body">
                                <p>Royal Luxury Linen, 42 Colonial Drive, Piscataway,</p>
                                <p>New Jersey, US - 08854</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-fax" aria-hidden="true"></i>
                                <h6>Website Customer Service Hours</h6>
                            </div>
                            <div class="media-body">
                                <p>Monday-Friday</p>
                                <p>9am - 5pm</p>
                                <p>Sat - Sun / Closed</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->