import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HomeSlider } from '../../../shared/data/slider';
import { LocalStorageService } from 'angular-web-storage';
import { GlobalService } from 'src/app/_common/global';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
  public brands: any = [];
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;
  @ViewChild("sliderPopUpModel", { static: false }) sliderPopUpModel: TemplateRef<any>;
  public closeResult: string;
  public modalOpen: boolean = false;
  public HomeSliderConfig: any = HomeSlider;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public local: LocalStorageService,
    public _global: GlobalService,
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void {
    console.log(this.sliders);
    this.branda();
  }

  branda() {
    let brand_item = this.local.get(this._global.SESSION_KEY + '_brands');
    console.log(brand_item);
    if (brand_item != null && brand_item['brands']) {
      this.brands = brand_item['brands'];
    }
  }

  // openWindow() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this._modalService.open(this.sliderPopUpModel, { 
  //       size: 'lg',
  //       ariaLabelledBy: 'Slider-Modal',
  //       centered: true,
  //       windowClass: 'theme-modal newsletterm SliderPopUpModel'
  //     }).result.then((result) => {
  //       this.modalOpen = true;
  //       `Result ${result}`
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     });
  //   }
  // }

  // 

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  ngOnDestroy() {
  //   if(this.modalOpen){
  //     this._modalService.dismissAll();
  //   }
  }

}
